import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./element-variables.scss";
//Note: 请注意：把下面代码放在tailwindcss/tailwind.css文件里面，代码位置一定要放在@tailwind base; 这行代码下方，
//因为这个会影响生成PDF证书文件里面文字显示位置
//@layer base {
//     img {
//         @apply inline-block;
//     }
// }
import "tailwindcss/tailwind.css";
import "normalize.css";
import router from "./router";
import UserData from "./utils/UserData";
import dayjs from "dayjs";
import locale from "element-ui/lib/locale/lang/en";
import ElInputTag from "el-input-tag";
import pdf from "./utils/pdf";

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
Vue.use(pdf);
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$UserData = UserData;
Vue.config.productionTip = false;

Vue.prototype.$globalMessage = new Vue();

Vue.use(ElementUI, { locale });
Vue.use(ElInputTag);

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");